import React, { useState, useContext } from "react";
import axios from "axios";
import "./footer.css";
import leave from "../../Assets/footerLeavesBgWhite.png";
import farmercultivating from "../../Assets/farmercultivating-removebg.png";
import config from "../../Boot/config.json";
import AlertDismissible from "../Utils/Alert";
import { AuthContext } from "../../App";

function Footer() {
  const { setTokenData } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    place: "",
  });

  const [phoneError, setPhoneError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Users`,
  });

  function alertMsg(messages, alertStatus) {
    setTokenData((prevState) => ({
      ...prevState,
      alertMsg: messages,
      alertValue: true,
      alertStatus,
    }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && !/^\d{0,10}$/.test(value)) {
      setPhoneError("Mobile number should contain only 10 digits.");
    } else {
      setPhoneError("");
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    axiosInstance
      .post("/ContactUs", formData)
      .then((response) => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          place: "",
        });
        alertMsg("Form submitted successfully", true);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 1500);
        setIsSubmitting(false);
      })
      .catch((error) => {
        alertMsg("Error while submitting form", false);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 1500);
        console.error("Error submitting form:", error);
      });
  };
  return (
    <div>
      <div className="position-relative footerWrapper">
        <img
          src={leave}
          alt="leaves"
          className="position-absolute footerLeaves"
          loading="lazy"
        />
        <div className="d-flex justify-content-center align-items-center mt-md-5 mt-2">
          <div className="cultivatingImg">
            <img
              src={farmercultivating}
              alt="farmer cultivation"
              className="farmerCultivateImg"
              loading="lazy"
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center letsConnect">
            <h1>
              Let's Cultivate Conversation: Reach Out to Us for Farming
              Solutions and Support
            </h1>
            <form action="" className="connectForm" onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="border-0"
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                />
              </div>
              <div>
                <input
                  type="text"
                  name="phone"
                  placeholder="Enter Phone"
                  className="border-0"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              {phoneError && <div className="error ">{phoneError}</div>}
              <div>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="border-0"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Enter Place"
                  className="border-0"
                  name="place"
                  value={formData.place}
                  onChange={handleChange}
                />
              </div>
              <button
                className="connectFormBtn border-0"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center p-1 bg-success text-white">
          <div className="me-0 me-sm-5 p-1">Email : jaikisan@jaikisan.com</div>
          <div className="p-1">Phone : +91 9885548859</div>
        </div>
        <div className="m-0 d-flex justify-content-center bg-success">
          <p className="m-0 mb-1">
            <b>Developed By:</b>
            <a
              href="https://svsrco.com"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-white"
            >
              &nbsp;&nbsp;SVSR Communications Pvt Ltd
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
