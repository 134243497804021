import React from "react";
import "./farmingPractices.css";
import organicFarming from "../../Assets/organicFarming.webp";
import permaculture from "../../Assets/Permaculture-Farming.jpg";
import regenrativeAgriculture from "../../Assets/regenrativeAgriculture.jpg";
import Agroecology from "../../Assets/Agroecology.jpg";
import { NavLink } from "react-router-dom";

function FarmingPractices() {
  const NaturalFarming = [
    {
      image: organicFarming,
      heading: "Organic Farming",
    },
    {
      image: permaculture,
      heading: "Permaculture",
    },
    {
      image: regenrativeAgriculture,
      heading: "Regenerative Agriculture",
    },
    {
      image: Agroecology,
      heading: "Agroecology",
    },
  ];
  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }
  return (
    <div>
      <div className="FarmingPracticesWrapper mt-2 pt-2 mt-sm-4 pt-sm-4 ">
        <h1>Natural Farming Practices</h1>
        <p className="w-100 w-md-50 text-center my-2 py-2 my-sm-3 py-sm-3">
          Discover the Power of Natural Farming Practices: Enhance Soil Health
          and Boost Crop Yields Naturally. Embrace Sustainable Agriculture with
          Our Expert Tips and Resources."
        </p>
        <div className="d-flex flex-wrap justify-content-center pt-3 mt-4 pt-md-0 mt-md-0">
          {NaturalFarming.map((farmingPractice) => (
            <NavLink
              to={`/NaturalFarming/${urlEncoded(farmingPractice.heading)}`}
              key={farmingPractice.heading}
            >
              <div className="farmingPractice m-2 position-relative">
                <div className="transperentLayer position-absolute"></div>
                <img
                  src={farmingPractice.image}
                  className="farmPracticeImg position-absolute"
                  alt={farmingPractice.heading}
                  loading="lazy"
                />
                <h1 className="position-absolute practiceName text-white d-flex justify-center align-items-center">
                  {farmingPractice.heading}
                </h1>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FarmingPractices;
