import React, { useContext, useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { AuthContext } from "../../App";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import jaikisanLogo from "../../Assets/jaikisanLogo.jpg";
import farmerMenu from "../../Assets/farmerMenu.png";
import config from "../../Boot/config.json";
import axios from "axios";
import profile from "../../Assets/profileImg.jpg";

import "./navigation.css";

function Navigation({ shadowSM }) {
  const { tokenData, handleLogout } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const [details, setDetails] = useState({
    role: "",
    userId: "",
  });

  const navigation = useNavigate();

  const [expanded, setExpanded] = useState(false);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Users`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  function navigateTo(navigateTo) {
    navigation(navigateTo);
    setExpanded(false); // Close the dropdown
  }

  // Effect to load values from session storage into state
  useEffect(() => {
    const storedToken = sessionStorage.getItem("JaikisanJWT");
    if (storedToken) {
      const authData = JSON.parse(storedToken);
      setDetails((prevData) => ({
        ...prevData,
        userId: authData.userId,
        role: authData.roleId,
      }));
    }
  }, [tokenData]);

  useEffect(() => {
    if (details.userId) {
      axiosInstance
        .get(`/User/${details.userId}`)
        .then((response) => {
          setUserData(response.data.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the user data!", error);
        });
    }
  }, [details.userId, details.role, tokenData.profileStatus]);

  return (
    <section>
      <Navbar
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        className={`p-2 d-flex position-relative ${shadowSM} `}
      >
        <Navbar.Brand>
          <img
            src={jaikisanLogo}
            alt="wellness yoga naturopathy logo"
            className="d-inline-block align-top logo "
            loading="lazy"
            onClick={() => navigateTo("/")}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          className="custom-toggler border-0"
          onClick={() => setExpanded(!expanded)}
        >
          <img src={farmerMenu} alt="menu" loading="lazy" />
        </Navbar.Toggle>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end mb-navbarC"
        >
          <Nav className="navLinks p-4 d-flex justify-content-center align-items-center">
            <NavLink to="/" onClick={() => navigateTo("/")}>
              HOME
            </NavLink>

            <a onClick={() => navigateTo("/")} href="#AboutUs">
              ABOUT
            </a>

            <NavLink to="/careers" onClick={() => navigateTo("/careers")}>
              JOBS
            </NavLink>

            <NavLink to="/blogs" onClick={() => navigateTo("/blogs")}>
              BLOGS
            </NavLink>

            {tokenData.token && (tokenData.roleId === "Admin" || "Guest") && (
              <NavLink
                to="/protected/myBlogs"
                onClick={() => navigateTo("/protected/myBlogs")}
              >
                MY BLOGS
              </NavLink>
            )}
            {tokenData.token &&
              (tokenData.roleId === "User" ||
                tokenData.roleId === "Guest" ||
                tokenData.roleId === "Admin") && (
                <NavLink
                  to="/agencies"
                  className="text-decoration-none text-center"
                  onClick={() => navigateTo("/agencies")}
                >
                  AGENCIES
                </NavLink>
              )}
            {tokenData.token && tokenData.roleId === "Admin" && (
              <NavLink
                to="/protected/Users"
                className="text-decoration-none text-center "
                onClick={() => navigateTo("/protected/Users")}
              >
                USERS
              </NavLink>
            )}

            {tokenData.token && (
              <NavLink
                to="/Home"
                className="text-decoration-none text-center"
                onClick={() => {
                  handleLogout();
                  setExpanded(false); // Close the dropdown
                }}
              >
                LOGOUT
              </NavLink>
            )}
          </Nav>
          {tokenData.profileStatus}
          <div className=" d-flex flex-column justify-content-center align-items-center">
            {tokenData.token && (
              <NavLink
                to={`/protected/UserProfile/${details.userId}`}
                title="profile"
                className="text-decoration-none  text-center profileImg rounded-circle d-flex flex-column justify-content-center align-items-center"
                onClick={() =>
                  navigateTo(`/protected/UserProfile/${details.userId}`)
                }
              >
                <img
                  src={
                    userData.profile === ""
                      ? profile
                      : `${config.deployUrl}${userData.profile}`
                  }
                  alt={userData.name}
                  className="profileImg rounded-circle"
                  loading="lazy"
                />
              </NavLink>
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </section>
  );
}

export default Navigation;
