import { useLocation } from "react-router-dom";
import FarmingHome from "../../FarmingHome/FarmingHome";
import AboutWebsite from "../../AboutWebsite/AboutWebsite";
import FarmingPractices from "../../FarmingPractices/FarmingPractices";
import SoilHealth from "../../SoilHealth/SoilHealth";
import CropManagement from "../../CropManagement/CropManagement";
import Footer from "../../Footer/Footer";
import Navigation from "../../Navigation/Navigation";

function Home() {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <section>
      <div id="Home">
        <FarmingHome />
      </div>
      <div id="AboutUs">
        <AboutWebsite />
      </div>
      <div>
        <FarmingPractices />
      </div>
      <div>
        <SoilHealth />
      </div>
      <div>
        <CropManagement />
      </div>
      <div id="Contact">
        <Footer />
      </div>
    </section>
  );
}

export default Home;
