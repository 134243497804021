import React from "react";
import "./farmingHome.css";
import Navigation from "../Navigation/Navigation";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function FarmingHome() {
  const navigation = useNavigate();

  function navigateTo(navigateTo) {
    navigation(navigateTo);
  }
  return (
    <div className="farmingBanner">
      <Helmet>
        <title>Home | Jaikisan</title>
        <meta
          name="description"
          content="Join Jaikisan to discover farming best practices, organic products, and farmer empowerment. Support sustainable agriculture and buy directly from farmers."
        />
      </Helmet>

      <Navigation shadowSM="shadow-none" />
      <div className="transpLayer"></div>
      <div className="farmingContent">
        <h1 className="farmerMotoPhrase text-center">
          Join Us in Growing Greener & Simple Solutions for Farmers
        </h1>
        <p className="FarmImprovementHub text-center">
          Join the farming revolution, practical, healthy, productive.
        </p>
        <a
          href="#AboutUs"
          className="disCoverFarmersWealth text-dark"
          onClick={() => navigateTo("/")}
        >
          Discover More
        </a>
      </div>
    </div>
  );
}

export default FarmingHome;
