import React from "react";
import "./aboutWebsite.css";
import soil from "../../Assets/soilTop.png";

function aboutWebsite() {
  return (
    <div>
      <div className="aboutWebsite">
        <p className="context">
          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This website dedicated to natural
          farming practices is to provide a comprehensive platform for farmers
          interested in sustainable and environmentally friendly agricultural
          techniques. This website serves as a digital hub where farmers can
          access a wealth of information, guidance, and resources on natural
          farming methods. Its primary goal is to promote practices that
          minimize the use of synthetic inputs such as chemical fertilizers and
          pesticides, while maximizing soil health, biodiversity, and long-term
          sustainability.
        </p>
        <p className="whatYouGet">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; On this website, farmers can find
          practical advice on implementing various natural farming techniques,
          including organic farming, permaculture, agroecology, and regenerative
          agriculture. They can learn about soil management strategies, crop
          rotation techniques, companion planting, and natural pest control
          methods.
        </p>

        <img src={soil} className="wetSoilBlack" alt="soil" loading="lazy" />
      </div>
    </div>
  );
}

export default aboutWebsite;
