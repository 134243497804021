import React from "react";
import "./soilHealth.css";
import composting from "../../Assets/composting.jpg";
import coverCrop from "../../Assets/coverCrop.jpg";
import soilTesting from "../../Assets/soilTesting.jpg";
import soilAmendements from "../../Assets/mixing-soil-with-amendments.jpg";
import potPlant from "../../Assets/potPlant.jpg";
import { NavLink } from "react-router-dom";

const soilHealth = [
  {
    image: composting,
    heading: "Composting",
  },
  {
    image: coverCrop,
    heading: "Cover Crop",
  },
  {
    image: soilTesting,
    heading: "Soil Testing",
  },
  {
    image: soilAmendements,
    heading: "Soil Amendments",
  },
];
function urlEncoded(title) {
  return title
    .toLowerCase() // Optional: convert to lowercase
    .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
}
function SoilHealth() {
  return (
    <div>
      <div>
        <h1 className="text-center m-2 p-2 m-sm-4 p-sm-4">Soil Health</h1>
        <div className="position-relative d-flex flex-wrap justify-content-center align-items-center soilHealthWrapper">
          <div className="transperentCircleLayer rounded-circle position-absolute"></div>
          <img
            src={potPlant}
            className="position-absolute potPlant"
            alt="pot plant"
            loading="lazy"
          />
          <div className="soilContent d-flex flex-column justify-content-center py-3">
            <h1 className="soilHealthHeading">
              <span className="unlockColor">Unlock</span> the Secrets Beneath
              Your Feet
            </h1>
            <p className="soilContentPara py-2 py-sm-4">
              Learn How Healthy Soil Can Transform Your Farm. Discover the Key
              to Sustainable Agriculture Through Soil Health Education and
              Practices.
            </p>
            <div className="position-relative">
              <a
                href="/SoilHealth/Composting"
                className="soilExplore bg-white text-dark "
              >
                Explore More
              </a>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center align-items-center soilHealthPractices">
            {soilHealth.map((healthOptions) => (
              <NavLink
                to={`/SoilHealth/${urlEncoded(healthOptions.heading)}`}
                className="soilHealthLinks"
              >
                <div className="soilPracticesImgs m-2 m-sm-4">
                  <img
                    src={healthOptions.image}
                    className="soilHealthImg"
                    alt="soil health"
                    loading="lazy"
                  />
                  <h1 className="soilPrecautionName tex-center text-dark d-flex justify-center align-items-center">
                    {healthOptions.heading.toUpperCase()}
                  </h1>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoilHealth;
