import React, { useState } from "react";
import GrassPotWithOutBg from "../../../Assets/GrassPotWithOutBg.png";
import "./blogs.css";
import potPlant from "../../../Assets/potPlant-removedbg.png";
import Navigation from "../../Navigation/Navigation";
import axios from "axios";
import config from "../../../Boot/config.json";
import { Helmet } from "react-helmet";

function Blogs({ onSearchChange, draftsMode }) {
  const [search, setSearch] = useState("");

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Blogs`,
  });

  function handleChange(e) {
    const { id, value } = e.target;
    if (id == "search") {
      setSearch(value);
      // onSearchChange(value);
    }
  }
  function handleSearchBlog(e) {
    e.preventDefault();
    onSearchChange(search);
  }

  return (
    <div className="Blogs">
      <Helmet>
        <title>
          Jaikisan Blogs: Farming Best Practices and Organic Agriculture
          Insights
        </title>
        <meta
          name="description"
          content="Explore Jaikisan's blog for insights on farming best practices, organic agriculture, and farmer empowerment. Stay informed and support sustainable farming."
        />
      </Helmet>

      <div className="blogsWrapper">
        <div className="d-flex justify-content-center h-100">
          <div className="blogSearch d-flex flex-column justify-content-center align-items-start">
            <div className="text-white blogsPhrase d-flex align-items-center">
              Growing Knowledge: Farmer Insights, Best Practices, and Expert
              Advice
            </div>
            <div>
              {!draftsMode && (
                <div className="searchBar d-flex  justify-content-center">
                  <form action="" onSubmit={handleSearchBlog}>
                    <input
                      type="text"
                      placeholder="Search Blogs...."
                      className="border-0"
                      id="search"
                      onChange={(e) => handleChange(e)}
                    />
                    <button className="border-0">Search</button>
                  </form>
                </div>
              )}
            </div>
          </div>
          <div className="BlogPotImg d-flex flex-column justify-content-center align-items-center">
            <img
              src={GrassPotWithOutBg}
              loading="lazy"
              alt="pot plant"
              className="blogsPotPlant"
            />
            {!draftsMode ? (
              <>
                <h1 className="text-white">BLOGS</h1>
              </>
            ) : (
              <>
                <h1 className="text-white">DRAFTS</h1>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
