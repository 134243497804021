import React, { useState, useContext } from "react";
import IndividualAgency from "./IndividualAgency";
import Agencies from "./Agencies";
import "./agencyView.css";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../App";

function AgencyView() {
  const [activeComponent, setActiveComponent] = useState("agencies");

  const { tokenData } = useContext(AuthContext);

  return (
    <div>
      <Helmet>
        <title> Agencies | Jaikisan </title>
        <meta
          name="description"
          content="Join Jaikisan's agency program to represent leading companies and sell their products. Partner with us in promoting sustainable agriculture and supporting local farmers."
        />
      </Helmet>

      {tokenData.token && tokenData.roleId === "User" && (
        <>
          <div className="status-bar shadow-sm">
            <button
              onClick={() => setActiveComponent("agencies")}
              className={
                activeComponent === "agencies" ? "active border-0" : "border-0"
              }
            >
              All Agencies
            </button>
            <button
              onClick={() => setActiveComponent("individualAgency")}
              className={
                activeComponent === "individualAgency"
                  ? "active border-0"
                  : "border-0"
              }
            >
              My Agency
            </button>
          </div>
        </>
      )}

      {activeComponent === "agencies" && <Agencies />}
      {activeComponent === "individualAgency" && (
        <IndividualAgency agencyIds={tokenData.agencyId} />
      )}
    </div>
  );
}

export default AgencyView;
