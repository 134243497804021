import {
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
  useParams,
} from "react-router-dom";
import { useEffect, useContext } from "react";
import FarmingSoilCropManagement from "../Components/Pages/farmingSoilCropManagement/FarmingSoilCropManagement";
import Home from "../Components/Pages/Home/Home";
import Blogs from "../Components/FarmersBlogs/Blogs/Blogs";
import Login from "../Components/LoginPage/Login";
import FarmersBlogPage from "../Components/FarmersBlogs/FarmersBlogPage";
import FarmersBlogs from "../Components/FarmersBlogs/FarmersBlogs";
import FarmersBlogEditor from "../Components/FarmersBlogsEditor/FarmersBlogEditor";
import { AuthContext } from "../App";
import DraftsComponent from "../Components/Drafts/DraftsComponent";
import CareerPage from "../Components/Jobs/CareerPage";
import JobEditor from "../Components/JobEditor/JobEditor";
import CareerDescription from "../Components/Jobs/CareerDescription";
import ApplicationForm from "../Components/Jobs/ApplicationForm";
import MyBlogs from "../Components/MyBlogs/MyBlogs";
import Users from "../Components/Users/Users";
import UserProfile from "../Components/Users/UserProfile";
import AddUser from "../Components/Users/AddUser";
import Agencies from "../Components/Agency/Agencies";
import IndividualAgency from "../Components/Agency/IndividualAgency";
import AgencyView from "../Components/Agency/AgencyView";
import farmingSoilCropJson from "../Components/Pages/farmingSoilCropJson.json";
import Error404 from "../Components/ErrorPage/Error404";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// const jsonData = JSON.parse(farmingSoilCropJson);

// Extract keys
const validTopics = Object.keys(farmingSoilCropJson);
console.log(validTopics);

function PrivateRoutes() {
  const { tokenData } = useContext(AuthContext);
  return tokenData.token ? <Outlet /> : <Navigate to="/blogs" />;
}

function PathConfig() {
  const { tokenData } = useContext(AuthContext);
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/:topic/:title" element={<ValidTopicAndTitle />} />
        <Route path="/blogs" element={<FarmersBlogs />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/careers" element={<CareerPage />} />
        <Route path="/blog/:title/:id" element={<FarmersBlogPage />} />
        <Route path="/careers/:title/:jobId" element={<CareerDescription />} />
        <Route path="/applylink/:title/:jobId" element={<ApplicationForm />} />
        <Route path="/agencies" element={<AgencyView />} />
        <Route
          path="/agency/:agencyName/:agencyId"
          element={<IndividualAgency />}
        />

        <Route path="/protected" element={<PrivateRoutes />}>
          <Route index element={<Navigate to="/protected/addPost" />} />
          <Route path="/protected/addPost" element={<FarmersBlogEditor />} />
          <Route path="/protected/drafts" element={<DraftsComponent />} />
          <Route path="/protected/addJob" element={<JobEditor />} />
          <Route path="/protected/myBlogs" element={<MyBlogs />} />
          <Route path="/protected/Users" element={<Users />} />
          <Route path="/protected/addUsers" element={<AddUser />} />
          <Route
            path="/protected/UserProfile/:userId"
            element={<UserProfile />}
          />

          {/*
            <Route path="/protected/addJob" element={<JobEditor />} /> */}
        </Route>
        {/* <Route path="/:topic/*" element={<FarmersBlogs />} /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}

function ValidTopicAndTitle() {
  const { topic, title } = useParams();
  // Check if the entered topic exists in the list of valid topics
  if (!validTopics.includes(title)) {
    // If not found, render the NotFoundPage
    return <Error404 />;
  }
  // If the topic is valid, render the component for topic and title
  return <FarmingSoilCropManagement />;
}
export default PathConfig;
