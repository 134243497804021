import "./App.css";
import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "./Components/Navigation/Navigation";
import AlertDismissible from "./Components/Utils/Alert";

import PathConfig from "./Routes/PathConfig";
import MoveToTop from "./Components/MoveToTop/MoveToTop";
export const AuthContext = createContext();

function App() {
  const [tokenData, setTokenData] = useState({
    token: "",
    roleId: "",
    userId: "",
    agencyId: "",
    alertMsg: "",
    alertValue: false,
    alertStatus: true,
    profileStatus: false,
  });
  const [pageNum, setPageNum] = useState(1);
  const [univPage, setUnivPage] = useState(1);
  const [paginNum, setPaginNum] = useState(1);

  const handleLogout = () => {
    sessionStorage.removeItem("JaikisanJWT");
    setTokenData((prevState) => ({
      ...prevState,
      token: "",
      roleId: "",
      userId: "",
      agencyId: "",
      alertMsg: "",
      alertValue: false,
      alertStatus: true,
    }));
  };

  useEffect(() => {
    const storedToken = sessionStorage.getItem("JaikisanJWT");
    if (storedToken) {
      const authData = JSON.parse(storedToken);
      setTokenData(authData);
    }
  }, []);

  const logout = () => {
    return tokenData ? (
      <div onClick={handleLogout}>Logout</div>
    ) : (
      <div style={{ display: "none" }}></div>
    );
  };

  const location = useLocation();
  console.log("location==============", location.pathname);
  const isHomeRoute =
    location.pathname === "/" || location.pathname === "/Home";

  return (
    <AuthContext.Provider
      value={{
        tokenData,
        handleLogout,
        logout,
        setTokenData,
        univPage,
        setUnivPage,
        pageNum,
        setPageNum,
        paginNum,
        setPaginNum,
      }}
    >
      <div className={` ${isHomeRoute ? "mt-0" : ""} App`}>
        {!isHomeRoute && (
          <Navigation shadowSM="shadow-sm position-fixed top-0 bg-white" />
        )}
        <PathConfig />
        <MoveToTop />
        <div
          className={`position-fixed   ${
            tokenData.alertValue ? "animateAlert " : "hideAlert"
          }`}
        >
          {tokenData.alertValue && (
            <AlertDismissible
              message={tokenData.alertMsg}
              value={tokenData.alertStatus}
            />
          )}
        </div>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
