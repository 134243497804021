import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../App";
import config from "../../Boot/config.json";
import "./agencies.css";
import { Link } from "react-router-dom";
import agencyFallBackImg from "../../Assets/agencyFallBackImg.png";
import NoData from "../../Assets/NoData.jpg";

function Agencies() {
  const { tokenData } = useContext(AuthContext);
  const [agencyData, setAgencyData] = useState({
    agencies: [],
    loading: true,
  });

  useEffect(() => {
    if (tokenData.token) {
      fetchAgencies();
    }
  }, [tokenData]);

  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Agencies`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  const fetchAgencies = () => {
    setAgencyData((prevAgencyData) => ({ ...prevAgencyData, loading: true }));

    axiosInstance
      .get("/allAgencies")
      .then((response) => {
        setAgencyData((prevAgencyData) => ({
          ...prevAgencyData,
          agencies: response.data.data,
          loading: false,
        }));
      })
      .catch((error) => {
        console.error("There was an error fetching the agencies!", error);
        setAgencyData((prevAgencyData) => ({
          ...prevAgencyData,
          loading: false,
        }));
      });
  };

  if (agencyData.loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="agencies-container">
      {agencyData.agencies && agencyData.agencies.length === 0 && (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center  noAgencyDataWrapper position-relative overflow-hidden ">
            <img
              src={NoData}
              alt="no agecies found"
              className="noAgenciesFound"
            />
            <h2>No Agencies Found</h2>
          </div>
        </>
      )}
      <div className="agencies-grid mt-2 mt-sm-4">
        {agencyData.agencies.map((agency) => (
          <div key={agency._id} className="agency-card position-relative">
            <div className="agency-card-image position-relative">
              <img
                src={
                  agency.agencyImage !== ""
                    ? `${config.deployUrl}${agency.agencyImage}`
                    : agencyFallBackImg
                }
                alt={agency.agencyName || "No Agency Name"}
              />
              <div
                className={`position-absolute top-0 end-0 p-2 text-white ${
                  agency.verified ? "bg-success" : "bg-danger"
                }`}
              >
                {agency.verified ? "Active" : "Inactive"}
              </div>
            </div>
            <div className="agency-card-content position-relative">
              <div className="d-flex justify-content-between">
                <h2 title={agency.agencyName}>
                  {agency.agencyName && agency.agencyName.length > 8
                    ? agency.agencyName.slice(0, 8) + "..."
                    : agency.agencyName || "No Agency Name"}
                </h2>
                <div className="ratingColor px-3 ">☆ {agency.ratingAgency}</div>
              </div>
              <p className="agencybout">
                {agency.about && agency.about.length > 100
                  ? agency.about.slice(0, 100) + "..."
                  : agency.about ||
                    "Share your company's mission, services, and uniqueness to stand out among competitors."}
              </p>
              <p>
                <strong>Agency Code:</strong> {agency.agencyCode}
              </p>
              <p title={agency.ownerName}>
                <strong> Name: </strong>
                {agency.email && agency.ownerName.length > 20
                  ? agency.ownerName.slice(0, 20) + "..."
                  : agency.ownerName || "N/A"}
              </p>
            </div>

            <div className=" position-absolute bottom-0 w-100 mb-3">
              <Link
                to={`/agency/${urlEncoded(
                  agency.agencyName
                )}/${agency.jkAgencyId.slice(4, 10)}`}
                className="d-flex justify-content-center"
              >
                <button className="w-50">View More</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Agencies;
