import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import "./individualAgency.css";
import config from "../../Boot/config.json";
import { AuthContext } from "../../App";
import agencyFallback from "../../Assets/agencyFallBackImg.png";
import establish from "../../Assets/Established.png";
import gstImg from "../../Assets/gst.png";
import licenseExp from "../../Assets/licenseExp.png";
import companyDocument from "../../Assets/companyDocs.png";
import companyOwner from "../../Assets/owner.png";
import emails from "../../Assets/email.png";
import phoneCall from "../../Assets/phoneCall.png";
import websiteLink from "../../Assets/websites.png";
import socialMedia from "../../Assets/social-media.png";
import deleteIcon from "../../Assets/deleteIcon.png";
import { Helmet } from "react-helmet";
import { IoLocationSharp } from "react-icons/io5";
import editPencil from "../../Assets/edit_pencil.png";

function IndividualAgency({ agencyIds }) {
  const { agencyId } = useParams();
  const [agency, setAgency] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);
  const [agencyDetails, setAgencyDetails] = useState({
    agencyDocPath: "",
    errorWhileEdit: "",
    states: [],
    cities: [],
    addCityBlockStat: false,
    addCityStat: false,
    previewImage: null,
    agencyDocFileName: "",
    userJKUId: "",
  });
  // const [previewImage, setPreviewImage] = useState(null);

  const { tokenData, setTokenData } = useContext(AuthContext);
  const today = new Date().toISOString().split("T")[0];
  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Agencies`,
    headers: {
      Authorization: tokenData?.token,
      "Content-Type": "multipart/form-data",
    },
  });

  const location = useLocation();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const fileInputRefDocs = useRef(null);

  // useEffect to check when token is loaded

  function fetchCities(data, value, fetchState) {
    try {
      axios
        .post(`${config.baseUrl}/Agencies/postStateChange`, data, {
          headers: {
            Authorization: tokenData.token,
          },
        })
        .then((res) => {
          setAgencyDetails((prevData) => ({
            ...prevData,
            cities: res.data.cities,
          }));
          setAgency((prev) => ({ ...prev, state: value }));

          setAgencyDetails((prevData) => ({
            ...prevData,
            addCityBlockStat: fetchState === "disable" ? false : true,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  // useEffect(() => {
  //   let data = { country: agency.country, state: agency.state };
  //   fetchCiites(data, agency.state);
  // }, [agencyDetails.addCityBlockStat]);

  useEffect(() => {
    if (tokenData && tokenData.token) {
      setIsTokenLoaded(true);
    }
  }, [tokenData]);

  useEffect(() => {
    if (!isTokenLoaded || !tokenData?.token) {
      return; // Wait for the token to be available
    }

    const identity = agencyId || agencyIds;
    // console.log("Checking if token is available: ", tokenData);

    axiosInstance
      .get(`agency/${identity}`)
      .then((response) => {
        console.log("API Response:", response.data); // Debugging: log the response data
        setAgency(response.data.data);
        setAgencyDetails((prevData) => ({
          ...prevData,
          agencyDocPath: response.data.docFilePath,
          userJKUId: response.data.userJKUId,
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error); // Debugging: log the error
        setError(error);
        setLoading(false);
      });
  }, [agencyId, agencyIds, editMode, isTokenLoaded, tokenData]);

  const handleAddCity = () => {
    setAgencyDetails((prevData) => ({
      ...prevData,
      addCityStat: !agencyDetails.addCityStat,
    }));
  };

  const handleEditClick = () => {
    setAgencyDetails((prev) => ({
      ...prev,
      previewImage: null,
    }));
    setAgencyDetails((prevData) => ({
      ...prevData,
      states: [],
      cities: [],
      addCityBlockStat: false,
      addCityStat: false,
    }));
    setEditMode(!editMode);
  };
  function convertDateString(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function alertMsg(messages, alertStatus) {
    setTokenData((prevState) => ({
      ...prevState,
      alertMsg: messages,
      alertValue: true,
      alertStatus,
    }));
  }
  const handleSaveClick = () => {
    const identity = agencyId || agencyIds;
    let est = convertDateString(agency.establishedDate);
    let licExp = convertDateString(agency.lisenceExp);
    if (est && licExp && est > licExp) {
      setAgencyDetails((prevState) => ({
        ...prevState,
        errorWhileEdit: "Established Date is greater than licenseExp",
      }));
      alertMsg("Established date exceeds license Expiry", false);
      setTimeout(() => {
        setTokenData((prevState) => ({ ...prevState, alertValue: false }));
      }, 2000);
      return;
    } else {
      setAgencyDetails((prevState) => ({
        ...prevState,
        errorWhileEdit: "",
      }));
    }

    const formData = new FormData();
    for (const key in agency) {
      if (key !== "website" && key !== "socialMediaLinks") {
        formData.append(key, agency[key]);
      }
    }
    // Append website and socialMediaLinks arrays as JSON strings
    formData.append("website", JSON.stringify(agency.website));
    formData.append(
      "socialMediaLinks",
      JSON.stringify(agency.socialMediaLinks)
    );

    // If you have an image file, append it to the FormData

    console.log(agency);
    console.log("formDate-----------------", formData);
    axiosInstance
      .patch(`/EditAgency/${identity}`, formData)
      .then((response) => {
        setAgency(response.data.data);
        setEditMode(() => false);
        alertMsg("Details updated successfully", true);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 2000);
      })
      .catch((error) => {
        setError(error);
        alertMsg("something went wrong", false);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 2000);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const numericOnly = value.replace(/\D/g, "");
      const truncatedValue = numericOnly.slice(0, 10);
      setAgency((prevFD) => ({ ...prevFD, [name]: truncatedValue }));
    } else if (name === "country") {
      let data = { country: value };
      try {
        axios
          .post(`${config.baseUrl}/Agencies/postStateChange`, data, {
            headers: {
              Authorization: tokenData.token,
            },
          })
          .then((res) => {
            setAgency((prev) => ({ ...prev, country: value }));
            setAgencyDetails((prevData) => ({
              ...prevData,
              states: res.data.states,
            }));
            setAgencyDetails((prevData) => ({
              ...prevData,
              cities: res.data.cities,
            }));
          });
      } catch (err) {
        console.log(err);
      }
    } else if (name === "state") {
      let data = { country: agency.country, state: value };
      //fetchCiites
      fetchCities(data, value, "");
    } else if (name === "city") {
      setAgency((prev) => ({ ...prev, city: value }));
    } else {
      setAgency({ ...agency, [name]: value });
    }
  };

  const handleAddCitites = async (e) => {
    e.preventDefault();
    try {
      const data = {
        city: agency.addCity,
        state: agency.state,
        country: agency.country,
      };
      const newCity = agency.addCity;
      if (!agency.addCity) {
        alertMsg("Please Enter City", false);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 2000);
      } else {
        await axios.post(`${config.baseUrl}/Agencies/addCity`, data, {
          headers: {
            Authorization: tokenData.token,
          },
        });
        alertMsg("City sucessfully added", true);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 2000);

        console.log(agency.country, agency.state);
        let citiesData = { country: agency.country, state: agency.state };
        //fetchCiites
        console.log("aggter addin gadd", data);
        const fetchState = "disable";
        fetchCities(citiesData, agency.state, fetchState);
        // setAgency((prevData) => ({
        //   ...prevData,
        //   city: newCity,
        // }));
        setAgencyDetails((prevData) => ({
          ...prevData,
          addCityBlockStat: false,
        }));
        // setAgency((prevData) => ({
        //   ...prevData,
        //   addCity: "",
        // }));
      }
    } catch (err) {
      if (err.response.data === "City already exists") {
        alertMsg("City already exists", false);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 2000);
      }
      console.log(err);
    }
  };

  const handleAddLink = (type) => {
    setAgency({
      ...agency,
      [type]: [...agency[type], ""],
    });
  };

  const handleRemoveLink = (index, type) => {
    const updatedLinks = [...agency[type]];
    console.log("updateLinks", updatedLinks);
    console.log("inex", index);
    updatedLinks.splice(index, 1);
    setAgency({
      ...agency,
      [type]: updatedLinks,
    });
  };
  const handleLinkChange = (e, index, type) => {
    console.log(agency[type]);
    const newLinks = agency[type].map((link, i) =>
      i === index ? e.target.value : link
    );
    setAgency({ ...agency, [type]: newLinks });
  };
  const handleImageClick = (e) => {
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAgencyDetails((prevData) => ({
          ...prevData,
          previewImage: reader.result,
        }));
        setAgency({ ...agency, agencyImage: file });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDocChange = (e) => {
    const file = e.target.files[0];
    setAgencyDetails((prevData) => ({
      ...prevData,
      agencyDocFileName: file.name,
    }));

    if (file) {
      setAgency({ ...agency, companyDocs: e.target.files[0] });
    }
  };
  if (loading) return <div>Loading...</div>;
  if (error) return <div>No agency Data</div>;
  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(`/${location.state.from}`);
    } else {
      navigate(-1); // This will go back to the last page in history
      // navigate(-1);
    }
  };

  const {
    agencyName,
    about,
    state,
    country,
    city,
    establishedDate,
    lisenceExp,
    gst,
    companyDocs,
    ownerName,
    phone,
    email,
    website,
    socialMediaLinks,
    products,
    paymentDetails,
    paymentStatus,
    renualStatus,
    verified,
    verifiedBy,
    ratingAgency,
    Reviews,
    agencyImage,
    docFilePath,
  } = agency;

  return (
    <div className="individual-agency">
      <Helmet>
        <title>{`${agencyName}`}</title>
        <meta
          name="description"
          content={`${agencyName} partners with Jaikisan to bring leading agricultural products to local farmers. Join us in promoting sustainable agriculture and supporting the farming community.`}
        />
      </Helmet>
      <button
        className="position-fixed start-0 mx-3 rounded-1 indAgencyBackBtn "
        onClick={() => handleBackClick()}
      >
        <FaArrowLeftLong />
      </button>
      <div className="agency-header d-flex flex-column flex-sm-row">
        {editMode ? (
          <div className="d-flex flex-column position-relative">
            <div className="position-absolute editAgencyImg d-flex">
              <img
                src={editPencil}
                className="p-1 editAgencyImgPen "
                alt="edit agency image1"
                onClick={(e) => handleImageClick(e)}
                loading="lazy"
              />
            </div>
            <img
              src={
                agencyDetails.previewImage ||
                (agencyImage === ""
                  ? agencyFallback
                  : `${config.deployUrl}${agencyImage}`)
              }
              alt="Agency"
              onClick={(e) => handleImageClick(e)}
              className="agency-image my-2"
              loading="lazy"
            />
            <input
              type="file"
              name="agencyImage"
              accept="image/jpeg, image/png"
              className=" agencyImageEdit"
              ref={fileInputRef}
              onChange={(e) => handleFileChange(e)}
            />
          </div>
        ) : (
          <img
            src={
              agencyImage === ""
                ? agencyFallback
                : `${config.deployUrl}${agencyImage}`
            }
            alt="Agency"
            className="agency-image my-2 "
            loading="lazy"
          />
        )}
        <div className="agency-info">
          {editMode ? (
            <input
              type="text"
              name="agencyName"
              value={agencyName}
              onChange={handleChange}
              className="agency-name-edit w-25"
            />
          ) : (
            <h1 className="agency-name">{agencyName}</h1>
          )}
          <div className="individualRatingColor px-3 py-1 agency-rating my-3">
            ☆ {agency.ratingAgency}
          </div>
          {tokenData.token &&
            ((tokenData.roleId === "User" &&
              tokenData.userId === agencyDetails.userJKUId) ||
              tokenData.roleId === "Admin") && (
              <button
                onClick={handleEditClick}
                className="me-2 rounded-5 indAgencyCancelBtn bg-secondary text-white"
              >
                {editMode ? "Cancel" : "Edit"}
              </button>
            )}

          {editMode && (
            <button
              onClick={handleSaveClick}
              className="rounded-5 indAgencySaveBtn bg-success text-white"
            >
              Save
            </button>
          )}
        </div>
      </div>

      <div className="agency-details">
        <div className="section">
          <h2>About</h2>
          {editMode ? (
            <textarea
              name="about"
              value={about}
              onChange={handleChange}
              className="aboutAgency"
              rows={5}
            ></textarea>
          ) : (
            <p>{about}</p>
          )}
        </div>
        <hr />
        <div className="section">
          <h2>Contact Details</h2>
          {editMode ? (
            <div>
              <label className="m-2">
                <strong>Name:</strong>
                <input
                  type="text"
                  name="ownerName"
                  value={ownerName}
                  onChange={handleChange}
                />
              </label>
              <label className="m-2">
                <strong>Email:</strong>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                />
              </label>
              <label className="mx-2">
                <strong>Phone:</strong>
                <input
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                />
              </label>
              <div className="d-flex flex-sm-row flex-column">
                <label className="m-2  w-50">
                  <strong>Website:</strong>
                  <div>
                    {website.map((link, index) => (
                      <div className="position-relative">
                        <input
                          key={`website-${index}`}
                          type="text"
                          value={link}
                          onChange={(e) =>
                            handleLinkChange(e, index, "website")
                          }
                          className="websiteLinks mt-2 "
                        />
                        <img
                          src={deleteIcon}
                          alt="deleteBtn"
                          className="deleteIcons  close-btn position-absolute end-0"
                          onClick={() => handleRemoveLink(index, "website")}
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => handleAddLink("website")}
                    className="indAgencyAddBtn m-2 "
                  >
                    Add
                  </button>
                </label>
                <label className="m-2  w-50">
                  <strong>Social Media:</strong>
                  <div>
                    {socialMediaLinks.map((link, index) => (
                      <div className="position-relative">
                        <input
                          key={`socialMediaLinks-${index}`}
                          type="text"
                          value={link}
                          className="socialLinks mt-2 "
                          onChange={(e) =>
                            handleLinkChange(e, index, "socialMediaLinks")
                          }
                        />
                        <img
                          src={deleteIcon}
                          alt="deleteBtn"
                          className="deleteIcons close-btn position-absolute end-0"
                          onClick={() =>
                            handleRemoveLink(index, "socialMediaLinks")
                          }
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => handleAddLink("socialMediaLinks")}
                    className="indAgencyAddBtn  m-2"
                  >
                    Add
                  </button>
                </label>
              </div>
            </div>
          ) : (
            <div>
              <div className="m-2 px-2 d-flex align-items-center">
                <img
                  src={companyOwner}
                  alt={ownerName}
                  className="establishedIcon mx-2"
                  loading="lazy"
                />
                <strong>Name:</strong> {ownerName}
              </div>
              <div className="d-flex flex-sm-row flex-column">
                <div className="m-2 px-2 d-flex align-items-center">
                  <img
                    src={emails}
                    alt={email}
                    className="establishedIcon mx-2"
                    loading="lazy"
                  />
                  <strong>Email:</strong> {email}
                </div>
              </div>
              <div className="m-2 px-2 d-flex align-items-center">
                <img
                  src={phoneCall}
                  alt={phone}
                  className="establishedIcon mx-2"
                  loading="lazy"
                />
                <strong>Phone:</strong> {phone}
              </div>
              <div className="d-flex flex-sm-row flex-column">
                <div className="m-2 px-2 ">
                  <div className="d-flex justify-content-start">
                    <img
                      src={websiteLink}
                      alt={website}
                      className="establishedIcon mx-2"
                      loading="lazy"
                    />
                    <strong>Website:</strong>
                  </div>
                  <div className="px-3">
                    {website.length > 0
                      ? website.map((link, index) => (
                          <span className="d-flex align-items-center ">
                            <div className="pe-1 mt-1">•</div>
                            <a
                              key={index}
                              href={
                                link.startsWith("http")
                                  ? link
                                  : `https://${link}`
                              }
                              target="_blank"
                              rel="noreferrer"
                              className="my-1 d-flex"
                            >
                              {link}
                            </a>
                          </span>
                        ))
                      : "N/A"}
                  </div>
                </div>
                <div className="m-2 px-2">
                  <div className="d-flex justify-content-start">
                    <img
                      src={socialMedia}
                      alt={socialMediaLinks}
                      className="establishedIcon mx-2"
                      loading="lazy"
                    />
                    <strong>Social Media:</strong>
                  </div>
                  <div className="px-3">
                    {socialMediaLinks.length > 0
                      ? socialMediaLinks.map((link, index) => (
                          <span className="d-flex align-items-center ">
                            <div className="pe-1 mt-1">•</div>
                            <a
                              key={index}
                              href={
                                link.startsWith("http")
                                  ? link
                                  : `https://${link}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="my-1 d-flex"
                            >
                              {link}
                            </a>
                          </span>
                        ))
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="section">
          <h2>Location</h2>
          {editMode ? (
            <div className="d-flex">
              <label className="mx-2  d-flex flex-column">
                <strong>Country:</strong>

                <select
                  onChange={handleChange}
                  name="country"
                  className="counStatCitSelect"
                  // value={agency.country}
                >
                  <option value="" disabled selected>
                    select Country
                  </option>
                  <option value="India">India</option>
                </select>
              </label>
              <label className="mx-2 d-flex flex-column">
                <strong>State:</strong>
                <select
                  onChange={handleChange}
                  name="state"
                  className="counStatCitSelect"
                  // value={agency.state}
                >
                  <option value="state" disabled selected>
                    Select State
                  </option>
                  {agencyDetails.states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </select>
              </label>
              <label className="mx-2 d-flex flex-column">
                <strong>City:</strong>
                <select
                  onChange={handleChange}
                  name="city"
                  className="counStatCitSelect"
                  // value={agency.city}
                >
                  <option value="city" disabled selected>
                    Select City
                  </option>
                  {agencyDetails.cities &&
                    agencyDetails.cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                </select>
              </label>

              {agencyDetails.addCityBlockStat && (
                <fieldset>
                  <div className="d-flex">
                    <div>
                      <input
                        type="checkbox"
                        id="addCityCheckbox"
                        checked={agencyDetails.addCityStat}
                        onChange={handleAddCity}
                      />
                    </div>
                    <label htmlFor="addCityCheckbox" className="ms-2">
                      Add City
                    </label>
                  </div>

                  {agencyDetails.addCityStat && (
                    <fieldset className="d-flex w-50">
                      <input
                        type="text"
                        id="addCity"
                        name="addCity"
                        className="p-1 rounded-0 addCity"
                        onChange={handleChange}
                      />
                      <button
                        className="border-0 rounded-0 addcityBtn text-white"
                        onClick={handleAddCitites}
                      >
                        Add
                      </button>

                      {error && error === "please enter city" ? (
                        <p className="text-danger">{error} </p>
                      ) : (
                        ""
                      )}
                    </fieldset>
                  )}
                </fieldset>
              )}
            </div>
          ) : (
            <div className="px-3 mx-3">
              <IoLocationSharp /> {city} , {state} , {country}
            </div>
          )}
        </div>
        <hr />
        <div className="section">
          <h2>License & Establishment</h2>
          {editMode ? (
            <>
              <div>
                <label className="mx-2">
                  <strong>Established:</strong>
                  <input
                    type="date"
                    name="establishedDate"
                    value={establishedDate || ""}
                    onChange={handleChange}
                    max={today}
                  />
                </label>
                <label className="mx-2">
                  <strong>License Expiry:</strong>
                  <input
                    type="date"
                    name="lisenceExp"
                    value={lisenceExp || ""}
                    onChange={handleChange}
                  />
                </label>
                <label className="mx-2">
                  <strong>GST:</strong>
                  <input
                    type="text"
                    name="gst"
                    value={gst || ""}
                    onChange={handleChange}
                  />
                </label>
                {verified === false && (
                  <label className="m-2">
                    <div>
                      <strong>Company Docs:</strong>
                    </div>
                    <div className="custom-file-upload">
                      <button
                        className="custom-file-button"
                        onClick={() => fileInputRefDocs.current.click()}
                        title={agencyDetails.agencyDocFileName}
                      >
                        {agencyDetails.agencyDocFileName
                          ? agencyDetails.agencyDocFileName.slice(0, 20)
                          : "Upload Documents"}
                      </button>

                      <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        name="companyDocs"
                        ref={fileInputRefDocs}
                        style={{ display: "none" }}
                        onChange={handleDocChange}
                      />
                    </div>
                  </label>
                )}
                {tokenData.roleId === "Admin" && (
                  <label className="m-2">
                    <strong>Company Docs Status:</strong>
                    <div>
                      <select
                        name="verified"
                        id="verified"
                        onChange={handleChange}
                        className="w-100 p-2 rounded-1"
                        value={verified}
                      >
                        <option value="true">Accepted</option>
                        <option value="false">Rejected</option>
                      </select>
                    </div>
                  </label>
                )}
              </div>
              {agencyDetails.errorWhileEdit ===
              "Established Date is greater than licenseExp" ? (
                <p className="text-danger">{agencyDetails.errorWhileEdit}</p>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="d-flex flex-sm-row flex-column">
              <div className="d-flex flex-column">
                <div className="m-2 px-2 d-flex  detail-row">
                  <img
                    src={establish}
                    alt="established"
                    className="establishedIcon mx-2"
                    loading="lazy"
                  />
                  <strong className="detail-label pe-2">Established</strong>
                  <span className="detail-colon">:</span>
                  <span className="detail-value">
                    {establishedDate && establishedDate !== "Invalid Date"
                      ? establishedDate
                      : "N/A"}
                  </span>
                </div>
                <div className="m-2 px-2 d-flex  detail-row">
                  <img
                    src={licenseExp}
                    alt="license expiry"
                    className="establishedIcon mx-2"
                    loading="lazy"
                  />
                  <strong className="detail-label pe-2">License Expiry</strong>
                  <span className="detail-colon">:</span>
                  <span className="detail-value">
                    {lisenceExp && lisenceExp !== "Invalid Date"
                      ? lisenceExp
                      : "N/A"}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="m-2 px-2 d-flex  detail-row">
                  <img
                    src={gstImg}
                    alt="GST"
                    className="establishedIcon mx-2"
                    loading="lazy"
                  />
                  <strong className="detail-label">GST</strong>
                  <span className="detail-colon">:</span>
                  <span className="detail-value">{gst || "N/A"}</span>
                </div>
                <div className="m-2 px-2 d-flex  detail-row">
                  <img
                    src={companyDocument}
                    alt="company document"
                    className="establishedIcon mx-2"
                    loading="lazy"
                  />
                  <strong className="detail-label">Company Docs</strong>
                  <span className="detail-colon">:</span>
                  <span className="detail-value">
                    {companyDocs ? (
                      <a
                        href={`${config.deployUrl}${agencyDetails.agencyDocPath}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <button className="border-0 mx-2 shadow-sm rounded-1">
                          View
                        </button>
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="agencyOtherDetails">
          <h2>Other Details</h2>
          <table className="table table-borderless custom-table">
            <tbody>
              <tr>
                <td className="label">
                  <strong>Products</strong>
                </td>
                <td className="colon">:</td>
                <td className="value">{products.join(", ") || "N/A"}</td>
              </tr>
              <tr>
                <td className="label">
                  <strong>Payment Details</strong>
                </td>
                <td className="colon">:</td>
                <td className="value">{paymentDetails || "N/A"}</td>
              </tr>
              <tr>
                <td className="label">
                  <strong>Payment Status</strong>
                </td>
                <td className="colon">:</td>
                <td className="value">
                  {paymentStatus ? "Completed" : "Pending"}
                </td>
              </tr>
              <tr>
                <td className="label">
                  <strong>Renewal Status</strong>
                </td>
                <td className="colon">:</td>
                <td className="value">
                  {renualStatus ? "Renewed" : "Not Renewed"}
                </td>
              </tr>
              <tr>
                <td className="label">
                  <strong>Verified</strong>
                </td>
                <td className="colon">:</td>
                <td className="value">{verified ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td className="label">
                  <strong>Verified By</strong>
                </td>
                <td className="colon">:</td>
                <td className="value">{verifiedBy || "N/A"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div className="agency-reviews">
        <h2>Reviews</h2>
        {Reviews.length > 0 ? (
          <ul>
            {Reviews.map((review, index) => (
              <li key={index}>
                <strong>User:</strong> {review.userId}
                <br />
                <strong>Review:</strong> {review.review}
                <br />
                <strong>Rating:</strong> {review.rating}
                <br />
              </li>
            ))}
          </ul>
        ) : (
          <p>No reviews yet.</p>
        )}
      </div>
    </div>
  );
}

export default IndividualAgency;
